body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: white;
  --textColor: black;
  --softBg: #f0f0f0;
  --softTextColor: #626262;
  --white: white;
  --themeColorDisable: rgb(65, 9, 20);
  --category-1: #57c4ff31;
  --category-2: #da85c731;
  --category-3: #7fb88133;
  --category-4: #ff795736;
  --category-5: #ffb04f45;
  --category-6: #5e4fff31;
}

.dark {
  --bg: #0f172a;
  --textColor: #ddd;
  --softBg: #1f273a;
  --softTextColor: #a6a6a6;
  --themeColor: #3eae3e;
}

.light {
  --bg: white;
  --textColor: black;
  --softBg: #f0f0f0;
  --softTextColor: #626262;
  --themeColor: #3eae3e;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  min-height: 100vh;
  background-color: var(--bg);
  color: var(--textColor);
}

.wrapper {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
}

.theme {
  color: var(--themeColor);
}

@media screen and (max-width: 1536px) {
  .wrapper {
    max-width: 1366px;
  }
}
@media screen and (max-width: 1280px) {
  .wrapper {
    max-width: 1024px;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    max-width: 768px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 640px;
  }
}
@media screen and (max-width: 640px) {
  .wrapper {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dart {
  font-family: inherit;
  cursor: pointer;
  position: relative;
  border: none;
  background: inherit;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  z-index: 0;
  width: max-content;
}

.dart:focus:after,
.dart:hover:after {
  width: 100%;
  left: 0%;
}

.dart:after {
  content: "";
  pointer-events: none;
  bottom: -0.125rem;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 0.125rem;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
  margin-bottom: -0.1875rem;
  background-color: var(--themeColor);
}

.darts {
  font-family: inherit;
  cursor: pointer;
  position: relative;
  border: none;
  background: inherit;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  z-index: 0;
  width: auto; /* Change max-content to auto */
  display: inline-block; /* Add this line */
  white-space: normal; /* Add this line */
}

.darts:focus:after,
.darts:hover:after {
  width: 100%;
  left: 0%;
  white-space: normal;
}

.darts:after {
  content: "";
  pointer-events: none;
  bottom: -0.125rem;
  left: 50%;
  position: absolute;
  width: auto;
  height: 0.125rem;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, top;
  margin-bottom: -0.1875rem;
  background-color: var(--themeColor);
  white-space: normal;
}

/* --------------------------   ALL START           -------- ------------- */

.themetoggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--textColor);
  border-radius: 50%;
}

.scrolltotop-container {
  font-size: 26px;
  background-color: var(--themeColor);
  padding: 5px 10px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--textColor);
}

.inline-block {
  display: inline-block;
}

/* --------------------------   ALL END        -------- ------------- */

/* --------------------------   NAVBAR START           -------- ------------- */

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.navbar-logo-name {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  color: var(--themeColor);
  align-items: center;
}

.navbar-logo-img {
  object-fit: cover;
}

.navbar-logo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.navbar-blogname {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-link {
  cursor: pointer;
}

.navbar-link:hover {
  color: var(--themeColor);
}

@media screen and (max-width: 1280px) {
  .navbar-blogname {
    font-size: 28px;
  }
  .navbar-links {
    font-size: 13px;
    gap: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-blogname {
    font-size: 25px;
  }
}
@media screen and (max-width: 768px) {
  .navbar-blogname {
    font-size: 20px;
  }

  .navbar-logo-img {
    gap: 5px;
  }

  .navbar-links {
    font-size: 13px;
    gap: 12px;
  }
}
@media screen and (max-width: 640px) {
  .navbar-links {
    justify-content: flex-end;
  }
  .navbar-link {
    display: none;
  }
}

/* --------------------------   NAVBAR END          -------- ------------- */

.navbar-burger {
  width: 20px;
  height: 16px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  display: none;
}

.navbar-line {
  width: 100%;
  height: 2px;
  background-color: var(--textColor);
}

.navbar-sidenav {
  visibility: hidden;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  overflow: auto;
  padding-top: 0;
  margin: 0;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  transition: all 0.4s ease-in;
}

.navbar-open {
  width: 320px;
  visibility: visible;
}

.navbar-close {
  font-size: 25px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin: 20px;
}

.navbar-closechild {
  width: max-content;
  border: solid 1px var(--textColor);
  padding: 0px 10px;
}

.navbar-sidelink {
  margin-bottom: 35px;
  font-size: 16px;
  cursor: pointer;
}

.navbar-sidelink:hover {
  color: var(--themeColor);
}

.navbar-close:hover {
  color: var(--themeColor);
}

.navbar-closechild:hover {
  border: solid 1px var(--themeColor);
}

@media screen and (max-width: 640px) {
  .navbar-burger {
    display: flex;
  }

  .navbar-link {
    display: none;
  }
}

/* --------------------------   AUTHLINKS END          -------- ------------- */
.footer-container {
  margin-top: 50px;
  padding: 50px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--softTextColor);
  border-top: 2px solid var(--themeColor);
  gap: 15px;
}

.footer-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.footer-logo-img {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-logo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.footer-logoText {
  font-size: 24px;
  color: var(--themeColor);
}

.footer-desc {
  font-weight: 300;
  font-size: 15px;
}

.footer-icons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.footer-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 100px;
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 300;
}

.footer-listTitle {
  font-weight: bold;
  color: var(--themeColor);
}

.footer-link:hover {
  color: var(--themeColor);
}

.footer-parent {
  display: flex;
  gap: 5px;
  margin: 10px 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.footer-copyright {
  display: flex;
  margin: 0;
  font-size: 14px;
}

.footer-xml {
  font-size: 14px;
  text-decoration: underline;
}

.footer-xml:hover {
  color: var(--themeColor);
  text-decoration: underline;
}

.footer-newslettertitle {
  font-size: 25px;
}
.footer-newsletter {
  background-color: var(--bg);
  min-width: 300px;
  max-width: 420px;
  padding: 5px 10px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 20px;
}

.footer-inputbox {
  padding: 10px 20px;
  border: 1px solid var(--softTextColor);
  appearance: none;
  border-radius: 8px;
  width: 70%;
}

.footer-button {
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  appearance: none;
  border: 1px solid var(--softTextColor);
  background: var(--bg);
  color: var(--softTextColor);
  cursor: pointer;
  border-radius: 8px;
  width: 25%;
}

.footer-button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.footer-caretup {
  font-size: 26px;
  background-color: var(--themeColor);
  padding: 5px 10px 0px 0px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--softTextColor);
}

.footer-parentx {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 10px 0;
  align-items: center;
  font-size: 15px;
}

.footer-copyrightx {
  display: flex;
  font-size: 14px;
}

.footer-xmlx {
  font-size: 16px;
  text-decoration: underline;
}

.footer-xmlx:hover {
  text-decoration: none;
}

.footer-caretupx {
  font-size: 26px;
  background-color: var(--themeColor);
  padding: 5px 10px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--softTextColor);
}

.footer-xmlup {
  display: flex;
  gap: 10px;
}

@media screen and (min-width: 768px) {
  .footer-parentx {
    display: none;
  }
  .footer-parent {
    display: flex;
    flex-direction: column;
  }

  .footer-newslettertitle {
    font-size: 20px;
  }
  .footer-newsletter {
    gap: 10px;
  }

  .footer-desc {
    font-weight: 250;
    font-size: 14px;
  }

  .footer-button {
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 8px;
    width: 25%;
  }
}
@media screen and (min-width: 1024px) {
  .footer-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .footer-parentx {
    display: flex;
  }
  .footer-parent {
    display: none;
  }

  .footer-newslettertitle {
    font-size: 20px;
  }
  .footer-newsletter {
    min-width: 250px;
    max-width: 300px;
    padding: 5px 5px;
    gap: 10px;
  }

  .footer-desc {
    font-weight: 250;
    font-size: 14px;
  }

  .footer-button {
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 8px;
    width: 25%;
  }
}

@media screen and (max-width: 1024px) {
  .footer-links {
    gap: 50px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 50px;
  }

  .footer-links {
    width: 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 640px) {
  .footer-links {
    font-size: 14px;
  }
}
/* -------------------------- FOOTER   END          -------- ------------- */

.featured-section {
  position: relative;
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--themeColor);
  opacity: 75%;
}

.featured-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: var(--white);
  display: flex;
  justify-content: center;
}

.featured-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.featured-description {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 150px;
}

.featured-button {
  border: 2px solid var(--white);
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.featured-button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

@media screen and (max-width: 1280px) {
  .featured-section {
    height: 28rem;
  }

  .featured-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .featured-description {
    font-size: 16px;
    padding: 0px 15px;
    margin-bottom: 15px;
  }

  .featured-button {
    padding: 10px 20px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 768px) {
  .featured-section {
    height: 28rem;
  }

  .featured-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .description {
    font-size: 15px;
    padding: 0px 20px;
    margin-bottom: 10px;
  }

  .featured-button {
    padding: 7px 15px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 640px) {
  .featured-section {
    height: 25rem;
  }

  .featured-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .featured-description {
    font-size: 14px;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .featured-button {
    padding: 7px 15px;
    border-radius: 8px;
  }
}

/* --------------------------    END          -------- ------------- */

.popular-category {
  margin-top: 35px;
  margin-bottom: 60px;
}

.popular-category-title {
  margin: 25px 0;
}

.popular-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.popular-category-list {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  width: calc(15% - 20px);
  height: 80px;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--themeColor);
}

.popular-category-list:hover {
  font-weight: bold;
  background-color: var(--themeColor);
  color: var(--white);
}

.category-1 {
  background-color: var(--category-1);
}

.category-2 {
  background-color: var(--category-2);
}

.category-3 {
  background-color: var(--category-3);
}

.category-4 {
  background-color: var(--category-4);
}

.category-5 {
  background-color: var(--category-5);
}

.category-6 {
  background-color: var(--category-6);
}

@media screen and (max-width: 1280px) {
  .popular-category-list {
    width: 20%;
  }
}
@media screen and (max-width: 1024px) {
  .popular-category-list {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  .popular-category-list {
    width: 45%;
  }
}
@media screen and (max-width: 640px) {
  .popular-category-list {
    width: 100%;
  }
}

/* --------------------------    END          -------- ------------- */

.most-recent-container {
  margin: 0;
}

.most-recent-container-title {
  margin: 10px 0 25px 0;
}

.most-recent-container-cardlists {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

/* --------------------------    END          -------- ------------- */

.card-list-container {
  margin: 20px 0;
  border: 1px solid var(--themeColor);
  min-width: 350px;
  max-width: 380px;
  gap: 10px;
}

@media screen and (max-width: 750px) {
  .card-list-container {
    margin: 5px 0;
    min-width: 100%;
    overflow: hidden;
  }
}

.card-list-container-img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  cursor: pointer;
  transition: transform 0.4s ease;
  padding: 10px;
}

.card-list-container-img:hover {
  cursor: pointer;
}

.title-chunk {
  background-color: var(--themeColor);
  margin: 10px 0;
  padding: 3px 2px;
  font-size: 25px;
  font-weight: bolder;
  width: max-content;
}

.title-chunk:hover {
  font-size: 26px;
}

.card-list-container-title-desc {
  padding: 20px 15px;
  height: 100px;
}

.card-list-container-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.card-list-container-title:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.card-list-container-desc {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
}

.card-list-container-icon-blogname {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px;
  color: var(--themeColor);
}

.card-list-container-icon-date {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* --------------------------    END          -------- ------------- */

.subnav-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  font-size: 14px;
  margin: 0;
}

.subnav-home:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.subnav-icon {
  margin-bottom: -3px;
}

.all-blog-subnav-title {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font-size: 14px;
  margin-top: 30px;
}

.all-blog-subnav {
  display: flex;
  align-items: flex-start;
}

.all-heading {
  margin: 30px 0 40px 0;
}

/* --------------------------    END          -------- ------------- */

.pagination-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 30px 0;
  gap: 10px;
}

.pagination-button {
  border: 1px solid var(--themeColor);
  padding: 10px 16px;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.pagination-active {
  background-color: var(--themeColor);
  color: var(--white);
}

.pagination-icon {
  margin-top: 0px;
}

.nextprev-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.nextprev-button {
  border: 1px solid var(--themeColor);
  padding: 10px 16px;
  font-size: small;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: bold;
  background-color: inherit;
  color: var(--textColor);
}

.nextprev-button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.nextprev-active {
  background-color: var(--themeColor);
  color: var(--white);
}

.nextprev-icon {
  margin-top: 0px;
}
/* --------------------------    END          -------- ------------- */

.cookie-consent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg);
  color: var(--softTextColor);
  padding: 15px 20px;
  text-align: center;
  border-top: 1px solid var(--themeColor);
}

@media screen and (max-width: 1536px) {
  .cookie-consent {
    padding: 15px 80px;
  }
}
@media screen and (max-width: 1280px) {
  .cookie-consent {
    padding: 15px 150px;
  }
}
@media screen and (max-width: 1024px) {
  .cookie-consent {
    padding: 15px 110px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-consent {
    padding: 15px 20px;
  }
}

.cookie-privacy {
  font: bold;
  text-decoration: underline;
}

.cookie-consent button {
  color: var(--softTextColor);
  border: 1px solid var(--themeColor);
  background-color: var(--bg);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.cookie-consent button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.link-list-container {
  margin-bottom: 20px;
  display: block;
}

.link-list-container-title:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

/* --------------------------    END          -------- ------------- */

/* styles.css */
.post-content {
  padding: 0px;
  margin: 0;
}

.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.post-content ul,
.post-content ol {
  margin-left: 20px;
}

.post-content span {
  margin: 0;
}

.post-content .lazy-img {
  margin: 20px 0;
  object-fit: cover;
}

.post-content div {
  font-size: 1.25rem;
}
.post-content p {
  font-size: 1rem;
  line-height: 1.5;
}
.post-content img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.post-children {
  margin-top: 30px;
}

.post-first-child {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .post-first-child {
    width: 80%;
  }
  .post-children {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 30px;
  }

  .post-last-child {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    gap: 20px;
  }
}
/* --------------------------    END          -------- ------------- */

.share-container {
  margin: 20px 0;
}

.share-icon-container {
  display: flex;
  margin: 25px 0;
  gap: 5px;
}

.share-icon {
  cursor: pointer;
}

.share-icon:hover {
  transform: scale(1.1);
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.category-list-button {
  padding: 5px 8px;
  border: 1px solid var(--themeColor);
  background-color: inherit;
  cursor: pointer;
  color: var(--softTextColor);
}

.category-list-button:hover {
  background-color: var(--themeColor);
  color: var(--white);
}

.category-list-button-active {
  background-color: var(--themeColor);
  color: var(--white);
}

.card-list-container {
  margin: 20px 0;
}

.search-input-container {
  width: max-content;
  border: 1px solid var(--themeColor);
  padding: 5px;
  display: flex;
  margin-bottom: 30px;
}

.search-input {
  width: 90%;
  padding: 10px 8px;
  text-decoration: none;
  min-width: 250px;
  border: none;
  outline: none;
}
.search-btn {
  margin: 0 10px;
  width: 30%;
  background-color: var(--themeColor);
  color: var(--white);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-not-found {
  margin: 10px 0;
}

.table-of-contents {
  margin: 20px 0;
  border: 1px solid var(--textColor);
  padding: 15px 10px;
}

.post-card-list-container-desc {
  display: flex;
  margin: 15px 0 30px 0;
  gap: 20px;
  font-size: 13px;
}

.post-card-list-container-icon-blogname {
  display: flex;
  gap: 5px;
  align-items: center;
}

.post-card-list-container-icon-date {
  display: flex;
  gap: 5px;
  align-items: center;
}

.toc-title {
  font-weight: bold;
  font-style: 16px;
}

.toc-flex {
  display: flex;
  justify-content: space-between;
}

.toc-button {
  background-color: inherit;
  padding: 3px 10px;
  margin-left: 25px;
  border: 1px solid var(--textColor);
  outline: none;
  color: var(--textColor);
}

.toc-button:hover {
  background-color: var(--textColor);
  color: var(--bg);
}

.toc-text {
  margin: 0;
  font-size: 12px;
}

.heading-2 {
  margin: 5px 0;
}
.heading-2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.heading-3 {
  margin: 3px 0;
}

.heading-4 {
  margin: 3px 0;
}

.count-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--themeColor);
  color: var(--textColor);
  height: auto;
  border-radius: 6px;
  margin: 10px 0;
  padding: 20px 10px;
}
.code-copy{
  display: flex;
  justify-content: space-between;
}

.count-code-page {
  text-align: center;
}

.countdown {
  font-size: 20px;
  font-weight: bolder;
}

.page-info {
  font-size: 16px;
}

.opacity-65 {
  opacity: 65%;
}

.code-container {
  max-width: 100px;
  overflow: auto;
  font-size: 12px;
}

.copy-button {
  padding: 6px;
  border-radius: 3px;
  text-align: center;
  background-color: var(--white);
  color: var(--themeColor);
  cursor: pointer;
}
.copy-button:hover {
  opacity: 65%;
}

.ads-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--textColor);
  margin: 8px;
  min-height: 250px;
}

.ads-container-title {
  margin: 0;
  font-size: 10px;
}
